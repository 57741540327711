import "./App.css";
import MainBanner from "./components/banner/MainBanner";
import FooterSection from "./components/footer/Footer";
import Header from "./components/Header";
import { useEffect, useState } from "react";

function App() {
  const [mode, setMode] = useState("dark");
  const [walletAddress, setWalletAddress] = useState(null);

  // const backgroundImage =
  //   mode === "dark" ? "../Dark-bg.svg" : "../Light-bg.svg";

  const handleModeChange = (newMode) => {
    setMode(newMode);
  };

  async function getConnectedWalletAddress() {
    if (window.ethereum) {
      try {
        // Request account access if needed
        // await window.ethereum.enable();

        // Get the connected wallet address
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });

        if (accounts.length > 0) {
          // Return the first account address
          return accounts[0];
        } else {
          // No accounts connected
          console.error("No wallet accounts connected");
          return null;
        }
      } catch (error) {
        // Handle any errors that occur during the request
        console.error("Error getting wallet address:", error);
        return null;
      }
    } else {
      // The browser does not have the Ethereum provider
      console.error("No Ethereum provider detected");
      return null;
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      getConnectedWalletAddress().then((address) => {
        setWalletAddress(address);
      });
    }, 2000); // Call every 2 seconds

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [window.ethereum]);

  return (
    <>
      {/* <MainBanner /> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          background: mode === "dark" ? "rgb(19 19 19)" : "#F6F6F6",

          // backgroundImage: `url(${backgroundImage})`,
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "cover",
          flexDirection: "column",
          // gap: "30px",
          overflowY: "auto",
        }}
      >
        <Header onModeChange={handleModeChange} walletAddress={walletAddress} />
        <iframe
          style={{
            margin: "auto",
            outline: "none",
            height: "90vh",
            border: "none",
            boxShadow: "0px 8px 32px rgba(0, 0, 0, 0.08)",
          }}
          src={`https://smolrefuel.com/?outboundChain=1&partner=0x10Cc9D85441f27a500776357758961031218E3aE&widget=basic`}
          title="Smol Refual Widget"
          height="100%"
          width="100%"
          allow="accelerometer; autoplay; camera; gyroscope; payment"
        />
      </div>
      <FooterSection />
    </>
  );
}

export default App;
