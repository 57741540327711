// Import the functions you need from the SDKs you need
const { initializeApp } = require('firebase/app')
const { collection, getDoc, doc, getFirestore } = require('firebase/firestore')
const { ethers } = require('ethers')

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

const db = getFirestore(app)

const getSharpePointsForUser = async documentId => {
  try {
    documentId = ethers.utils.getAddress(documentId)
    
    const apiUrl = `https://base-api.sharpe.ai/api/getTotalSharpePoints/${documentId}`;

    const response = await fetch(apiUrl);
    console.log("response, ", response)
    const data = await response.json();
    
    if (!data.status) {
      return "0";
    }
    return data.sharpePointScaled.toString();
  } catch (err) {
    console.error('getSharpePoints: failed with error : ', err)
    throw new Error('getSharpePoints: failed')
  }
}

module.exports = {
  getSharpePointsForUser,
  db,
}
