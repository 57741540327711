import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  Transition,
} from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { FolderIcon } from "@heroicons/react/24/outline";
import { debounce } from "lodash";

const mainWebsiteApiUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PUBLIC_API_URL
    : process.env.REACT_APP_PUBLIC_LOCAL_API_URL;

const GlobalSearch = ({ openSearch, handleClose }) => {
  const [query, setQuery] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${mainWebsiteApiUrl}/routes`);
      const result = await response.json();
      // Pre-process the data
      const processedData = Object.entries(result).flatMap(([ticker, routes]) =>
        Object.entries(routes).map(([route, routeParam]) => ({
          ticker,
          route: route.toLowerCase().replace(/\s+/g, "-"),
          routeName: route,
          routeParam,
          searchString: `${ticker} ${route}`.toLowerCase(),
        }))
      );
      setData(processedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (openSearch) {
      fetchData();
    }
  }, [fetchData, openSearch]);

  useEffect(() => {
    if (!openSearch) {
      setQuery("");
    }
  }, [openSearch]);

  const closeDialog = useCallback(() => {
    handleClose();
    setQuery("");
  }, [handleClose]);

  const handleItemClick = useCallback(
    (ticker, route, routeParam) => {
      const newPath = `/${route}/${routeParam}`;
      const baseUrl = "https://sharpe.ai/";
      window.open(`${baseUrl}${newPath}`, "_self");
      closeDialog();
    },
    [closeDialog]
  );

  const filteredData = useMemo(() => {
    if (query === "") {
      return data.slice(0, 100);
    }

    const lowercasedTerm = query.toLowerCase();
    return data
      .filter((item) => item.searchString.includes(lowercasedTerm))
      .slice(0, 100);
  }, [query, data]);

  const debouncedSetQuery = useMemo(
    () => debounce((value) => setQuery(value), 300),
    []
  );

  const handleInputChange = useCallback(
    (event) => {
      debouncedSetQuery(event.target.value);
    },
    [debouncedSetQuery]
  );

  const LoaderComponent = () => (
    <div className="flex flex-col h-[400px] justify-center items-center py-8">
      <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-neutral-400 mb-4"></div>
      <p className="text-neutral-400 text-sm font-medium">
        Preparing your search experience...
      </p>
      <p className="text-neutral-500 text-xs mt-2">This may take a moment</p>
    </div>
  );

  return (
    <Transition.Root show={openSearch} as={React.Fragment}>
      <Dialog as="div" className="relative z-[999999]" onClose={closeDialog}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-800 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-2xl mt-20 transform divide-y divide-neutral-500 divide-opacity-20 overflow-hidden rounded-xl bg-neutral-950 shadow-2xl transition-all">
              <Combobox
                onChange={(item) => {
                  if (item && item.ticker && item.route && item.routeParam) {
                    handleItemClick(item.ticker, item.route, item.routeParam);
                  }
                }}
              >
                <div className="relative divide-y divide-neutral-500 divide-opacity-20">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-neutral-500"
                    aria-hidden="true"
                  />
                  <ComboboxInput
                    autoFocus
                    className="h-12 w-full border-0 focus:outline-none  bg-transparent pl-11 pr-4 text-white focus:ring-0 focus:border-none sm:text-sm"
                    placeholder="Search..."
                    onChange={handleInputChange}
                  />
                </div>

                {isLoading ? (
                  <LoaderComponent />
                ) : filteredData.length > 0 ? (
                  <ComboboxOptions
                    static
                    as="ul"
                    className="max-h-80 scroll-py-2 divide-y divide-neutral-500 divide-opacity-20 overflow-y-auto"
                  >
                    <li className="p-2 ">
                      <h2 className="mb-4  mt-2 px-3 text-xs font-semibold tracking-wider text-neutral-200">
                        {query === "" ? "Trending Search" : "Search Results"}
                      </h2>
                      <ul className="text-sm text-neutral-400">
                        {filteredData.map((item) => (
                          <ComboboxOption
                            key={`${item.ticker}-${item.routeName}`}
                            value={item}
                            className="group flex cursor-pointer select-none items-center rounded-md px-3 py-2 data-[focus]:bg-neutral-900 data-[focus]:text-white"
                          >
                            {/* {(item.route === 'options' || item.route === 'futures') && (
                              <img
                                src={`../../../coins-logo-png/${item.ticker.replace(/^1000/, '').toLowerCase()}.png`}
                                alt={`${item.ticker} logo`}
                                style={{ marginRight: '8px', height: '20px', width: '20px', borderRadius: '50%' }}
                              />
                            )} */}
                            <span className="ml-3 flex-auto truncate">
                              {item.ticker}{" "}
                              <span className="text-neutral-500">
                                /{item.routeName}
                              </span>
                            </span>
                            <span className="ml-3 hidden flex-none text-neutral-400 group-data-[focus]:inline">
                              Jump to...
                            </span>
                          </ComboboxOption>
                        ))}
                      </ul>
                    </li>
                  </ComboboxOptions>
                ) : (
                  query !== "" && (
                    <div className="px-6 py-14 text-center sm:px-14">
                      <FolderIcon
                        className="mx-auto h-6 w-6 text-neutral-500"
                        aria-hidden="true"
                      />
                      <p className="mt-4 text-sm text-neutral-200 ">
                        We couldn&apos;t find any projects with that term.
                        Please try again.
                      </p>
                    </div>
                  )
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default GlobalSearch;
